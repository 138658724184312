import PropTypes from "prop-types"
import React from "react"

import styles from "./headline.module.css"

const Headline = ({ children, tag, visualStyle, align, className }) => {
  const Tag = tag;
  visualStyle = visualStyle ? styles[visualStyle] : styles[tag];
  align = align ? styles[align] : '';

  return (
    <Tag className={[styles.headline, visualStyle, align, className].join(' ')}>
      {children}
    </Tag>
  )
}

Headline.propTypes = {
  tag: PropTypes.string,
  visualStyle: PropTypes.string,
  align: PropTypes.string,
  className: PropTypes.string
}

Headline.defaultProps = {
  tag: 'h1',
  visualStyle: '',
  align: '',
  className: '',
}

export default Headline
